import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import React from "react";
import { MainPage } from "./pages/Main";

Sentry.init({
  dsn: "https://b1f87c1802ca4d849ccc7568a2b56ad1@o1075679.ingest.sentry.io/6076495",
  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.1,
});

function App() {
  return <MainPage />;
}

export default App;
