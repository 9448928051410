//@ts-ignore
import ReactFullpage from "@fullpage/react-fullpage";
import React, { useCallback, useEffect, useState } from "react";
//@ts-ignore
import CoinbaseCommerceButton from "react-coinbase-commerce";
import "react-coinbase-commerce/dist/coinbase-commerce-button.css";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { isMobile, browserName } from "react-device-detect";
import Lottie from "react-lottie-player";
import QRCode from "react-qr-code";
import {
  FacebookIcon,
  FacebookShareButton,
  TelegramIcon,
  TelegramShareButton,
  TwitterIcon,
  TwitterShareButton,
  ViberIcon,
  ViberShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
import { UniswapVersion } from "simple-uniswap-sdk";
import {
  ChainId,
  ETH,
  UniswapDappSharedLogicContext,
} from "uniswap-dapp-integration-shared";
import UniswapReact from "uniswap-react";
import "../App.css";
import AppleStoreLogo from "../resources/images/apple_store2.svg";
import PlayStoreLogo from "../resources/images/play_store2.svg";
import ChromeWebStoreLogo from "../resources/images/ChromeWebStoreBorder.png";
import CoinbaseWalletLogo from "../resources/images/coinbase-wallet.png";
import MetamaskLogo from "../resources/images/metamask-logo.svg";
import UniswapLogo from "../resources/images/uniswap.svg";
import MintMeLogo from "../resources/images/mintMe.svg";
import dappA from "../resources/lottie/app2.json";
import coinsA from "../resources/lottie/crypto-coins.json";
import databaseA from "../resources/lottie/database3.json";
import megaphoneA from "../resources/lottie/megaphone.json";
import nftA from "../resources/lottie/nft2.json";
import supportA from "../resources/lottie/support.json";
import eCommerceA from "../resources/lottie/eCommerce3.json";

import styles from "./Main.module.css";
import WalletLink from "walletlink";
import Web3 from "web3";

import { Helmet } from "react-helmet";

const DEV_WALLET = "0xC31C8E8890687cD0FC4Cedb66416713A0E8C17cE";

const SUPPORTED_TOKENS = [
  {
    contractAddress: "0x7d1afa7b718fb893db30a3abc0cfc608aacfebb0",
  },
  {
    contractAddress: "0x514910771af9ca656af840dff83e8264ecf986ca",
  },
  {
    contractAddress: "0x6b175474e89094c44da98b954eedeac495271d0f",
  },
  {
    contractAddress: "0x95aD61b0a150d79219dCF64E1E6Cc01f0B64C4cE",
  },
  {
    contractAddress: "0xa0b73e1ff0b80914ab6fe0444e65848c4c34450b",
  },
  {
    contractAddress: "0xdac17f958d2ee523a2206206994597c13d831ec7",
  },
  {
    contractAddress: "0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48",
  },
];

// Coinbase Wallet
const APP_NAME = "DAT Token";
const APP_LOGO_URL = '"https://www.dattoken.com/logo/DAT_512x512.png';
const ETH_JSONRPC_URL =
  "https://mainnet.infura.io/v3/1518baf0013d417a9e16e0df54695b05";

// Metamask Wallet (Used to  add token to watch list)
const tokenAddress = "0x2e6da3122bef86593400d63fbe81b539f33e52f3";
const tokenSymbol = "DAT";
const tokenDecimals = 18;
const tokenImage = "https://www.dattoken.com/logo/DAT_512x512.png";

// Metamask DeepLink
const METAMASK_MOBILE_URL = "https://metamask.app.link/dapp/www.dattoken.com/";

const UNISWAP_SWAP_URL =
  "https://app.uniswap.org/#/swap?theme=dark&use=v3&outputCurrency=0x2e6da3122bef86593400d63fbe81b539f33e52f3";
const UNISWAP_ADD_LIQUDITY_URL =
  "https://app.uniswap.org/#/add/ETH/0x2e6da3122bef86593400d63fbe81b539f33e52f3";
const PANCAKESWAP_SWAP_URL =
  "https://pancakeswap.finance/swap?outputCurrency=0x2e6da3122bef86593400d63fbe81b539f33e52f3";
const PANCAKESWAP_ADD_LIQUIDITY_URL =
  "https://pancakeswap.finance/add/BNB/0x2e6Da3122bEF86593400D63fbe81b539F33e52f3";

const PAGE_ANCHORS = [
  "token",
  "whitepaper",
  "datapp",
  "datwallet",
  "datcommunity",
  "datsupport",
];

const isAnimationEnabled = true;
export function MainPage() {
  const [isCopied, setIsCopied] = useState<boolean>(false);
  const [colorScheme, setColorScheme] = useState<"dark" | "light">();

  const [uniswapDappSharedLogicContext, setUniswapDappSharedLogicContext] =
    React.useState<undefined | UniswapDappSharedLogicContext>(undefined);

  // Handles ColorScheme and ColorScheme change
  useEffect(() => {
    if (
      window.matchMedia &&
      window.matchMedia("(prefers-color-scheme: dark)").matches
    ) {
      // dark mode
      setColorScheme("dark");
    } else {
      // light mode
      setColorScheme("light");
    }
    window
      .matchMedia("(prefers-color-scheme: dark)")
      .addEventListener("change", (e) => {
        const newColorScheme = e.matches ? "dark" : "light";
        setColorScheme(newColorScheme);
      });
  }, []);

  // Handles Initial Load on Mobile if Web3 provided
  // On Web, don't connect automatically, let the user decide what wallet to connect to
  useEffect(() => {
    if (
      isMobile &&
      (window.ethereum?.isMetaMask || window.ethereum?.isCoinbaseWallet)
    ) {
      // @ts-ignore
      window.ethereum
        .request({
          method: "eth_requestAccounts",
        })
        // @ts-ignore
        .then((accounts: string[]) => {
          const uniswapDappSharedLogicContext: UniswapDappSharedLogicContext = {
            supportedNetworkTokens: [
              {
                chainId: ChainId.MAINNET,
                defaultInputToken: ETH.MAINNET().contractAddress,
                defaultOutputToken:
                  "0x2e6Da3122bEF86593400D63fbe81b539F33e52f3",
                supportedTokens: [
                  { contractAddress: ETH.MAINNET().contractAddress },
                  {
                    tokenImageContext: {
                      image: "https://dattoken.com/logo/DAT_200x200.png",
                      isSvg: false,
                    },
                    contractAddress:
                      "0x2e6da3122bef86593400d63fbe81b539f33e52f3",
                  },
                  ...SUPPORTED_TOKENS,
                ],
              },
            ],
            ethereumAddress: accounts[0],
            ethereumProvider: window.ethereum,
            settings: {
              slippage: 1500,
              deadlineMinutes: 20,
              disableMultihops: false,
              uniswapVersions: [UniswapVersion.v3],
            },
          };

          setUniswapDappSharedLogicContext(uniswapDappSharedLogicContext);
          return;
        });
    }
  }, []);

  const handleAddDATTokenToWallet = useCallback(async () => {
    try {
      // wasAdded is a boolean. Like any RPC method, an error may be thrown.

      let provider;
      if (window.ethereum?.isMetaMask) {
        // @ts-ignore
        provider = window.ethereum.providers.find(
          // @ts-ignore
          (provider) => provider.isMetaMask
        );
      } else if (window.ethereum?.isWalletLink) {
        // @ts-ignore
        provider = window.ethereum.providers.find(
          // @ts-ignore
          (provider) => provider.isWalletLink
        );
      } else if (window.ethereum?.isCoinbaseWallet) {
        // @ts-ignore
        provider = window.ethereum.providers.find(
          // @ts-ignore
          (provider) => provider.isCoinbaseWallet
        );
      }

      // @ts-ignore
      const wasAdded = await provider.request({
        method: "wallet_watchAsset",
        params: {
          // @ts-ignore
          type: "ERC20", // Initially only supports ERC20, but eventually more!
          options: {
            address: tokenAddress, // The address that the token is at.
            symbol: tokenSymbol, // A ticker symbol or shorthand, up to 5 chars.
            decimals: tokenDecimals, // The number of decimals in the token
            image: tokenImage, // A string url of the token logo
          },
        },
      });

      if (wasAdded) {
        console.log("Thanks for your interest!");
      } else {
        console.log("Your loss!");
      }
    } catch (error) {
      console.log(error);
    }
  }, []);

  const connectToMetaMask = useCallback(() => {
    if (isMobile) {
      window.open(METAMASK_MOBILE_URL, "_self");
    } else {
      // @ts-ignore
      const metamaskProvider = window.ethereum.providers.find(
        // @ts-ignore
        (provider) => provider.isMetaMask
      );

      // @ts-ignore
      metamaskProvider
        .request({
          method: "eth_requestAccounts",
        })
        // @ts-ignore
        .then((accounts: string[]) => {
          console.log(`[MetaMask] User's address is ${accounts[0]}`);
          const uniswapDappSharedLogicContext: UniswapDappSharedLogicContext = {
            supportedNetworkTokens: [
              {
                chainId: ChainId.MAINNET,
                defaultInputToken: ETH.MAINNET().contractAddress,
                defaultOutputToken:
                  "0x2e6Da3122bEF86593400D63fbe81b539F33e52f3",
                supportedTokens: [
                  { contractAddress: ETH.MAINNET().contractAddress },
                  {
                    tokenImageContext: {
                      image: "https://dattoken.com/logo/DAT_200x200.png",
                      isSvg: false,
                    },
                    contractAddress:
                      "0x2e6da3122bef86593400d63fbe81b539f33e52f3",
                  },
                  ...SUPPORTED_TOKENS,
                ],
              },
            ],
            // @ts-ignore
            ethereumAddress: accounts[0],
            ethereumProvider: metamaskProvider,
            settings: {
              slippage: 1500,
              deadlineMinutes: 20,
              disableMultihops: false,
              uniswapVersions: [UniswapVersion.v3],
            },
          };

          setUniswapDappSharedLogicContext(uniswapDappSharedLogicContext);
        });
    }
  }, []);

  const connectToWalletLink = useCallback(() => {
    const walletLink = new WalletLink({
      appName: APP_NAME,
      appLogoUrl: APP_LOGO_URL,
      darkMode: false,
    });

    const ethereum = walletLink.makeWeb3Provider(
      ETH_JSONRPC_URL,
      ChainId.MAINNET
    );

    if (isMobile) {
      // This will open CoinbaseWallet App on mobile;
      ethereum.enable();
    } else {
      // Initialize a Web3 object
      const web3 = new Web3(ethereum);

      // This will show QR code to connect to CoinbaseWallet App or Google Chrome extension;
      ethereum.enable().then((accounts: string[]) => {
        console.log(`[CoinBase] User's address is ${accounts[0]}`);
        web3.eth.defaultAccount = accounts[0];

        const uniswapDappSharedLogicContext: UniswapDappSharedLogicContext = {
          supportedNetworkTokens: [
            {
              chainId: ChainId.MAINNET,
              defaultInputToken: ETH.MAINNET().contractAddress,
              defaultOutputToken: "0x2e6Da3122bEF86593400D63fbe81b539F33e52f3",
              supportedTokens: [
                { contractAddress: ETH.MAINNET().contractAddress },
                {
                  tokenImageContext: {
                    image: "https://dattoken.com/logo/DAT_200x200.png",
                    isSvg: false,
                  },
                  contractAddress: "0x2e6da3122bef86593400d63fbe81b539f33e52f3",
                },
                ...SUPPORTED_TOKENS,
              ],
            },
          ],
          // @ts-ignore
          ethereumAddress: accounts[0],
          ethereumProvider: window.ethereum,
          settings: {
            slippage: 1500,
            deadlineMinutes: 20,
            disableMultihops: false,
            uniswapVersions: [UniswapVersion.v3],
          },
        };

        setUniswapDappSharedLogicContext(uniswapDappSharedLogicContext);
      });
    }
  }, []);

  return (
    <div>
      <Helmet>
        <meta
          name="theme-color"
          content={
            colorScheme === "light" ? "RGB(254,254,254)" : "RGB(33,33,33)"
          }
        />
      </Helmet>

      <header
        id={"#MyHeader"}
        style={{
          zIndex: 99,
          height: "70px",
          position: "fixed",
          width: "100%",
          paddingTop: 8,
        }}
      >
        <ul
          id="#MyMenu"
          style={{
            height: "100%",
            listStyleType: "none",
            margin: 0,
            padding: 0,
            overflow: "hidden",
          }}
        >
          <li data-menuanchor="token" style={{ float: "left" }}>
            <a
              href="#token"
              style={{
                display: "block",
                color: "white",
                textAlign: "center",
                paddingLeft: isMobile ? 10 : 16,
                paddingRight: isMobile ? 10 : 16,
                paddingTop: 16,
                paddingBottom: 16,
                textDecoration: "none",
                fontWeight: 900,
              }}
            >
              DAT TOKEN
            </a>
          </li>
          <li data-menuanchor="token" style={{ float: "right" }}>
            <a
              href={UNISWAP_SWAP_URL}
              target="_blank"
              className={styles.myBtnImage}
              style={{
                marginLeft: isMobile ? 10 : 16,
                marginRight: isMobile ? 10 : 16,
                paddingTop: 8,
                paddingBottom: 8,
                paddingLeft: 16,
                paddingRight: 16,
                backgroundColor:
                  browserName === "Chrome"
                    ? colorScheme === "light"
                      ? "white"
                      : "RGB(54,55,59)"
                    : browserName === "Safari" ||
                      browserName === "Mobile Safari"
                    ? colorScheme === "light"
                      ? "RGB(247,247,247)"
                      : "RGB(43,43,43)"
                    : colorScheme === "light"
                    ? "white"
                    : "RGB(20, 20, 20)",
              }}
            >
              <img
                height={isMobile ? 30 : 35}
                src={UniswapLogo}
                alt="Connect to Uniswap"
              />
            </a>
          </li>

          {!isMobile && (
            <>
              <li data-menuanchor="datcommunity" style={{ float: "right" }}>
                <CoinbaseCommerceButton
                  checkoutId={"a764857c-97a2-44f3-94b9-6c06de2d905d"}
                  className={styles.navLink}
                  style={{
                    backgroundColor: "transparent",
                    border: "0px",
                  }}
                >
                  Support
                </CoinbaseCommerceButton>
              </li>
              <li data-menuanchor="datcommunity" style={{ float: "right" }}>
                <a href="#datcommunity" className={styles.navLink}>
                  Community
                </a>
              </li>
              <li data-menuanchor="datwallet" style={{ float: "right" }}>
                <a href="#datwallet" className={styles.navLink}>
                  Wallet
                </a>
              </li>
              <li data-menuanchor="datapp" style={{ float: "right" }}>
                <a href="#datapp" className={styles.navLink}>
                  Exchange
                </a>
              </li>
              <li data-menuanchor="whitepaper" style={{ float: "right" }}>
                <a href="#whitepaper" className={styles.navLink}>
                  Platform
                </a>
              </li>
            </>
          )}
        </ul>
      </header>

      <footer
        className={styles.footer}
        style={{
          zIndex: 99,
          bottom: 0,
          height: "60px",
          position: "fixed",
          width: "100%",
          paddingLeft: 16,
          paddingRight: 16,
          backgroundColor:
            browserName === "Chrome"
              ? colorScheme === "light"
                ? "white"
                : "RGB(54,55,59)"
              : browserName === "Safari" || browserName === "Mobile Safari"
              ? colorScheme === "light"
                ? "RGB(247,247,247)"
                : "RGB(43,43,43)"
              : colorScheme === "light"
              ? "white"
              : "RGB(20, 20, 20)",
          display: "flex",
          flex: "1",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          alignContent: "center",
          borderTopLeftRadius: isMobile ? 20 : undefined,
          borderTopRightRadius: isMobile ? 20 : undefined,
        }}
      >
        <div>
          <h1
            className={styles.headingMd}
            style={{
              color: colorScheme === "light" ? "black" : "white",
              fontWeight: 500,
            }}
          >
            DAT Developer Conference 2023
          </h1>
          <h1
            className={styles.headingSm}
            style={{
              color: colorScheme === "light" ? "black" : "white",
              fontWeight: 400,
            }}
          >
            Contact Center -{" "}
            <a
              style={{
                fontWeight: 400,
                color:
                  colorScheme === "light"
                    ? "RGB(61,125,240)"
                    : "RGB(91,146,248)",
              }}
              href="mailto:support@dattoken.com"
            >
              support@dattoken.com
            </a>{" "}
          </h1>
        </div>
        <div
          style={{
            borderLeftWidth: 1,
            borderLeftColor: "white",
            borderLeftStyle: "solid",
            paddingLeft: 16,
            marginLeft: 16,
          }}
        >
          <a
            href="https://www.mintme.com/token/Decentralized-Application-Token/MINTME/trade"
            className={styles.navLink}
            style={{ padding: 0 }}
          >
            <img
              height={isMobile ? 30 : 35}
              src={MintMeLogo}
              alt="Trade on MintMe.com"
            />
          </a>
        </div>
      </footer>

      <main>
        <ReactFullpage
          licenseKey={"158F9813-9E85447A-9778E21A-AC4E3863"}
          scrollingSpeed={1000}
          anchors={PAGE_ANCHORS}
          navigation={false}
          navigationTooltips={PAGE_ANCHORS}
          sectionsColor={[
            "#0052fe",
            "#0052fe",
            "black",
            "#0052fe",
            "#0052fe",
            "#0052fe",
          ]}
          menu={"#MyMenu"}
          paddingTop={"70px"}
          paddingBottom={"60px"}
          fixedElements={"#header, .footer"}
          // @ts-ignore
          render={() => {
            return (
              <ReactFullpage.Wrapper>
                {/*  token page */}
                <div className="section">
                  <div className={styles.container}>
                    <div
                      className={styles.headingBlock}
                      style={{ paddingTop: 0 }}
                    >
                      <h1 className={styles.heading2Xl}>
                        Decentralized Application Token
                      </h1>
                      <h2 className={styles.headingLg}>
                        DeFi, eCommerce, IPFS, and NFT solution for your web and
                        mobile applications
                      </h2>
                    </div>

                    <div>
                      {uniswapDappSharedLogicContext !== undefined ? (
                        <div style={{ minWidth: "343px" }}>
                          <UniswapReact
                            uniswapDappSharedLogicContext={
                              uniswapDappSharedLogicContext
                            }
                          />
                        </div>
                      ) : (
                        <div
                          style={{
                            display: "flex",
                            flex: 1,
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <Lottie
                            loop
                            play={isAnimationEnabled}
                            animationData={coinsA}
                            style={{ width: "50%", height: "50%" }}
                          />

                          <div className={styles.headingBlock}>
                            <h1 className={styles.headingXl}>
                              Select your wallet to connect
                            </h1>
                            <h2 className={styles.heading}>
                              For cash transactions we reccomend using
                              Coinbase Wallet
                            </h2>
                          </div>

                          <div
                            style={{
                              display: "flex",
                              paddingTop: 16,
                            }}
                          >
                            <button
                              onClick={connectToMetaMask}
                              className={styles.myBtnImage}
                              style={{ marginRight: 8 }}
                            >
                              <img
                                height={isMobile ? 50 : 60}
                                src={MetamaskLogo}
                                alt="Connect to MetaMask Wallet"
                              />
                            </button>
                            <button
                              onClick={connectToWalletLink}
                              className={styles.myBtnImage}
                              style={{ marginLeft: 8 }}
                            >
                              <img
                                height={isMobile ? 50 : 60}
                                src={CoinbaseWalletLogo}
                                alt="Connect to Coinbase Wallet"
                              />
                            </button>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                {/*  whitepaper page */}
                <div className="section">
                  <div className="slide" data-anchor="slide1">
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        flex: 1,
                        paddingLeft: 16,
                        paddingRight: 16,
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          flex: 1,
                        }}
                      >
                        <h1 className={styles.heading2Xl}>Make DeFi Apps</h1>
                        <h2 className={styles.headingLg}>
                          Web 3.0 standard to connect applications to the Ethereum and
                          Binance Smart Chain blockchain networks
                        </h2>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                          flex: 1,
                          paddingTop: 32,
                          paddingBottom: 32,
                        }}
                      >
                        <Lottie
                          loop
                          play={isAnimationEnabled}
                          animationData={dappA}
                          style={{ width: "33%", height: "33%" }}
                        />
                      </div>

                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          flex: 1,
                        }}
                      >
                        <h1 className={styles.headingLgn}>
                          Use DAT as your In-App currency or accept digital
                          crypto payments
                        </h1>
                        <h2 className={styles.headingMd}>
                          Simple integration. Fast, Global and Secure
                        </h2>
                      </div>
                    </div>
                  </div>

                  <div className="slide" data-anchor="slide1">
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        flex: 1,
                        paddingLeft: 16,
                        paddingRight: 16,
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          flex: 1,
                        }}
                      >
                        <h1 className={styles.heading2Xl}>
                          DeFi and NFT Support
                        </h1>
                        <h2 className={styles.headingLg}>
                          Future proof your application with the powerful
                          digital ledger
                        </h2>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                          flex: 1,
                          paddingTop: 32,
                          paddingBottom: 32,
                        }}
                      >
                        <Lottie
                          loop
                          play={isAnimationEnabled}
                          animationData={nftA}
                          style={{ width: "33%", height: "33%" }}
                        />
                      </div>

                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          flex: 1,
                        }}
                      >
                        <h1 className={styles.headingLgn}>
                          Convert digital goods into NFT's
                        </h1>
                        <h2 className={styles.headingMd}>
                          Start sellings In-Game Collectibles, Skins, Digital
                          Authograps and more within minuties
                        </h2>
                      </div>
                    </div>
                  </div>

                  <div className="slide" data-anchor="slide1">
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        flex: 1,
                        paddingLeft: 16,
                        paddingRight: 16,
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          flex: 1,
                        }}
                      >
                        <h1 className={styles.heading2Xl}>
                          Utilize DAT with eCommerce
                        </h1>
                        <h2 className={styles.headingLg}>
                          Accept crypto payments for goods and services
                        </h2>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                          flex: 1,
                          paddingTop: 32,
                          paddingBottom: 32,
                        }}
                      >
                        <Lottie
                          loop
                          play={isAnimationEnabled}
                          animationData={eCommerceA}
                          style={{ width: "33%", height: "33%" }}
                        />
                      </div>

                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          flex: 1,
                        }}
                      >
                        <h1 className={styles.headingLgn}>
                          Tap into a global customer base within minutes. Thanks
                          to the blockchain.
                        </h1>
                        <h2 className={styles.headingMd}>
                          International borders are a thing of the past
                        </h2>
                      </div>
                    </div>
                  </div>

                  <div className="slide" data-anchor="slide1">
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        flex: 1,
                        paddingLeft: 16,
                        paddingRight: 16,
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          flex: 1,
                        }}
                      >
                        <h1 className={styles.heading2Xl}>Connect to IPFS</h1>
                        <h2 className={styles.headingLg}>
                          The InterPlanetary File System powers the Distributed
                          Web
                        </h2>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                          flex: 1,
                          paddingTop: 32,
                          paddingBottom: 32,
                        }}
                      >
                        <Lottie
                          loop
                          play={isAnimationEnabled}
                          animationData={databaseA}
                          style={{ width: "33%", height: "33%" }}
                        />
                      </div>

                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          flex: 1,
                        }}
                      >
                        <h1 className={styles.headingLgn}>
                          Secure P2P hypermedia protocol
                        </h1>
                        <h2 className={styles.headingMd}>
                          Databases are thing of the past. Start storing and
                          retrieving files to and from a distributed network
                        </h2>
                      </div>
                    </div>
                  </div>
                </div>

                {/*  exchange page */}
                <div className="section">
                  <div className={styles.container}>
                    <div className={styles.headingBlock}>
                      <h1 className={styles.heading2Xl}>DAT Exchange</h1>
                      <h2 className={styles.headingMd}>
                        Scan QR code to access the exchage platform
                      </h2>
                    </div>

                    <QRCode
                      value="https://app.uniswap.org/#/swap?theme=dark&use=v3&outputCurrency=0x2e6da3122bef86593400d63fbe81b539f33e52f3"
                      size={256}
                    />
                  </div>
                </div>

                {/*  wallet page */}
                <div className="section">
                  <div className={styles.container}>
                    <div className={styles.headingBlock}>
                      <h1 className={styles.heading2Xl}>
                        DAT Wallet - Q1 2023
                      </h1>
                      <h2 className={styles.headingLg}>
                        iOS/Android App + Google Chrome Extension
                      </h2>

                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <div
                          className={styles.myBtnImage}
                          style={{
                            backgroundColor: "transparent",
                            paddingRight: 16,
                          }}
                        >
                          <img
                            width={"100%"}
                            src={AppleStoreLogo}
                            alt="App Store"
                          />
                        </div>

                        <div
                          className={styles.myBtnImage}
                          style={{
                            backgroundColor: "transparent",
                            paddingLeft: 16,
                          }}
                        >
                          <img
                            width={"100%"}
                            src={PlayStoreLogo}
                            alt="Google Play Store"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/*  community page */}
                <div className="section">
                  <div className={styles.container}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Lottie
                        loop
                        play={isAnimationEnabled}
                        animationData={megaphoneA}
                        style={{ width: "50%", height: "50%" }}
                      />
                    </div>

                    <div className={styles.headingBlock}>
                      <h1 className={styles.heading2Xl}>DAT Community</h1>
                      <h2 className={styles.headingLg}>
                        Help us grow the community and establish decentralized
                        Web 3.0 future for mobile and web applications!
                      </h2>
                    </div>

                    <div>
                      <FacebookShareButton
                        url="https://dattoken.com"
                        quote="Decentralized Application Token"
                        children={<FacebookIcon />}
                      />
                      <TwitterShareButton
                        url="https://dattoken.com"
                        title="Decentralized Application Token"
                        hashtags={[
                          "DAAPS",
                          "DAT",
                          "DATtoken",
                          "DecentralizedApplicationToken",
                        ]}
                        children={<TwitterIcon />}
                      />
                      <TelegramShareButton
                        url="https://dattoken.com"
                        title="Decentralized Application Token"
                        children={<TelegramIcon />}
                      />
                      <WhatsappShareButton
                        url="https://dattoken.com"
                        title="Decentralized Application Token"
                        children={<WhatsappIcon />}
                      />
                      <ViberShareButton
                        url="https://dattoken.com"
                        title="Decentralized Application Token"
                        children={<ViberIcon />}
                      />
                    </div>
                  </div>
                </div>

                {/*  support page */}
                <div className="section">
                  <div
                    className={styles.container}
                    style={{
                      flex: 1,
                      display: "flex",
                      flexDirection: "column",
                      height: "100%",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%",
                        width: "100%",
                      }}
                    >
                      {/* Animation block */}
                      <Lottie
                        loop
                        play={isAnimationEnabled}
                        animationData={supportA}
                        style={{ width: "33%", height: "33%" }}
                      />

                      {/* Header blocl */}
                      <div className={styles.headingBlock}>
                        <h1 className={styles.heading2Xl}>DAT Support</h1>
                        <h2 className={styles.headingMd}>
                          If you'd like to donate to the <b>Devs</b>, send{" "}
                          <b>DAT</b> or <b>BNB</b>/<b>ETH</b>. Thank you your
                          support!
                        </h2>
                      </div>

                      {/* DEV wallet */}
                      <div
                        style={{
                          padding: 16,
                          marginTop: 32,
                          backgroundColor: "#0052fe",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <div
                            style={{
                              position: "relative",
                              overflow: "hidden",
                              maxWidth: isMobile ? "230px" : "256px",
                            }}
                          >
                            <div
                              style={{
                                padding: 16,
                                borderRadius: 16,
                                color: isCopied ? "#fcd661" : "white",
                                fontWeight: 600,
                                overflow: "hidden",
                              }}
                            >
                              {DEV_WALLET}
                            </div>
                            <div className={styles.hide} />
                          </div>

                          <CopyToClipboard
                            text={DEV_WALLET}
                            onCopy={() => setIsCopied(true)}
                          >
                            <button
                              style={{
                                display: "block",
                                color: isCopied ? "black" : "white",
                                textAlign: "center",
                                padding: 16,
                                textDecoration: "none",
                                fontSize: "large",
                                fontWeight: 700,
                                borderWidth: 2,
                                borderColor: "#fcd661",
                                borderStyle: "solid",
                                borderRadius: 20,
                                paddingLeft: 32,
                                paddingRight: 32,
                                backgroundColor: isCopied
                                  ? "#fcd661"
                                  : "transparent",
                                marginLeft: 16,
                                marginRight: 16,
                                width: 128,
                              }}
                            >
                              {isCopied ? "Copied" : "Copy"}
                            </button>
                          </CopyToClipboard>
                        </div>
                      </div>
                    </div>

                    {/* DISCLOSURE */}
                    <div>
                      <p style={{ fontSize: "x-small", maxWidth: 500 }}>
                        DISCLOSURE By purchasing DAT Token, you agree that you
                        are not purchasing a security or investment contract and
                        you agree to hold the team harmless and not liable for
                        any losses or taxes you may incur. Although DAT is a
                        community driven DeFi Ecosystem and not a registered
                        digital currency, always make sure that you are in
                        compliance with local laws and regulations before you
                        make any purchase. Cryptocurrencies are not legal tender
                        and are not investments.
                      </p>
                    </div>
                  </div>
                </div>
              </ReactFullpage.Wrapper>
            );
          }}
        />
      </main>
    </div>
  );
}
